import { UserIcon, UsersIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import H2 from "./Content/H2";
import H3 from "./Content/H3";
import AdressFormular, { AdressFormularDummy } from "./Form/AdressFormular";
import DocField from "./Form/DocField";
import TextField from "./Form/TextField";
import RegistrationHeader from "./RegistrationHeader";

export default function HomeContent2() {

    const { setBaseInfo, baseInfo } = useContext(GlobalContext);

    const [formState, setFormState] = useState({
        name: baseInfo?.name || "",
        adresse: baseInfo?.adresse || "",
        telefon: baseInfo?.telefon || "",
        geburtsdatum: baseInfo?.geburtsdatum || "",
        vorname: baseInfo?.vorname || "",
        plz: baseInfo?.plz || "",
        ort: baseInfo?.ort || "",
        email: baseInfo?.email || "",
        datenUebernahme: true,
        eigentuemerSchaft: "alleine"
    });

    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setFormState({
            ...formState,
            [name]: value,
        })
    }

    const fieldsLeft = [
        {
            id: "name",
            label: "Name",
            type: "text",
            autoFocus: true,
            tabIndex: 1,
        },
        {
            id: "strasse",
            label: "Strasse",
            type: "text",
            autoFocus: false,
            tabIndex: 3,
        },
        {
            id: "plz",
            label: "PLZ",
            type: "text",
            autoFocus: false,
            tabIndex: 5,
        },
        {
            id: "zivilstand",
            label: "Zivilstand",
            type: "checkbox",
            autoFocus: false,
            tabIndex: 7,
        },
        {
            id: "geburtsdatum",
            label: "Geburtsdatum",
            type: "text",
            autoFocus: false,
            tabIndex: 9,
        },
        {
            id: "telefon",
            label: "Telefon",
            type: "text",
            autoFocus: false,
            tabIndex: 11,
        }
    ]

    const fieldsRight = [
        {
            id: "vorname",
            label: "Vorname",
            type: "text",
            autoFocus: false,
            tabIndex: 2,
        },
        {
            id: "nummer",
            label: "Nummer",
            type: "text",
            autoFocus: false,
            tabIndex: 4,
        },
        {
            id: "ort",
            label: "Ort",
            type: "text",
            autoFocus: false,
            tabIndex: 6,
        },
        {
            id: "beziehungsdstatus",
            label: "Beziehungsstatus",
            type: "checkbox",
            autoFocus: false,
            options: [
                "bitte auswählen...",
                "sonstiges",
                "verheiratet",
                "verwitwet",
                "in Partnerschaft",
            ],
            tabIndex: 7,
        },
        {
            id: "email",
            label: "E-Mail",
            type: "text",
            autoFocus: false,
            tabIndex: 9
        },

    ]

    return (
        <div className="py-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
            <div className="max-w-max lg:max-w-7xl mx-auto px-2">
                <div className="relative z-10 mb-8 md:mb-2 md:px-6">

                    <RegistrationHeader
                        active={1}
                        title="Finanzierungsantrag"
                    />

                    <H2>
                        Personenbezogene Angaben
                    </H2>
                </div>

                <div className="relative z-10 my-8 md:mb-2 md:px-6 flex justify-between">

                    <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-theme_blue pb-8">
                        Ich kaufe die Immobilie alleine oder zu zweit:
                    </h2>

                    <div
                        className="flex gap-8 pl-8"
                    >
                        <button
                            className={`bg-theme_green flex flex-col
                             items-center justify-center h-[180px] w-[180px] text-white text-2xl 
                             font-bold transition-colors border-2
                             ${formState.eigentuemerSchaft === "alleine" ? "bg-theme_blue" : "hover:bg-theme_orange"}
                             `}
                            onClick={() => setFormState({ ...formState, eigentuemerSchaft: "alleine" })}
                        >
                            <UserIcon
                                className="w-1/2"
                            />
                            alleine
                        </button>
                        <button
                            className={`bg-theme_green flex flex-col
                            items-center justify-center h-[180px] w-[180px] text-white text-2xl
                        font-bold transition-colors 
                        ${formState.eigentuemerSchaft === "zu zweit" ? "bg-theme_blue" : "hover:bg-theme_orange"}
                             `}
                            onClick={() => setFormState({ ...formState, eigentuemerSchaft: "zu zweit" })}
                        >
                            <UsersIcon
                                className="w-1/2"

                            />
                            zu zweit
                        </button>
                    </div>

                </div>


                <div className="relative z-10 mt-16 mb-8 md:mb-2 md:px-6 flex gap-4">

                    <div className={"mt-6 text-3xl leading-8 font-extrabold tracking-tight text-theme_blue pb-2 border-b-2 border-theme_blue border-x-none border-t-none flex flex-row w-full items-center justify-start"}>
                        <h3 className={"max-w-prose lg:max-w-none flex"}>
                            Meine Daten
                        </h3>

                        <div
                            className="ml-4 flex items-center justify-center cursor-pointer">
                            <div className="relative flex items-start">
                                <div className="flex justify-center items-center h-5">
                                    <input
                                        id="datenUebernahme"
                                        name="datenUebernahme"
                                        type="checkbox"
                                        className="focus:ring-theme_blue h-6 w-6 text-theme_blue border-gray-300 rounded cursor-pointer"
                                        checked={formState.datenUebernahme}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="comments" className="font-medium text-gray-700 cursor-pointer">Datenübernahme aus Profil</label>
                                </div>

                            </div>
                        </div>


                    </div>


                </div>



                <div className="relative">

                    <div className="relative md:bg-white md:p-6">

                        <div
                            className=""
                            id="adresse-1"
                        >
                            <AdressFormularDummy
                                handleInputChange={() => { }}
                                formState={baseInfo}
                            />

                            <div className="mb-8 bg-white overflow-hidden text-left">
                                <div className="max-w-max lg:max-w-7xl mx-auto">
                                    <H3>
                                        Dokumente
                                    </H3>
                                </div>
                                <div className="relative">

                                    <div className="relative md:bg-white pb-8">
                                        <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                            <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                                <DocField
                                                    id="name"
                                                    label="Gültige Ausweiskopie"
                                                    type="text"
                                                    placeholder="Mustermann"
                                                    autoFocus={true}
                                                />
                                                <DocField
                                                    id="adresse"
                                                    label="Lohnausweise"
                                                    type="text"
                                                    placeholder="Dorfstrasse 42"
                                                />
                                                <DocField
                                                    id="telefon"
                                                    label="Kopie der letzten Steuererklärung"
                                                    type="tel"
                                                    placeholder="XXX XXX XX XX"
                                                />
                                            </div>
                                            <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                                <DocField
                                                    id="vorname"
                                                    label="Betreibungsregisterauszug"
                                                    type="text"
                                                    placeholder="Hans"
                                                />
                                                <DocField
                                                    id="plz"
                                                    label="Belege zu verfügbarem Eigenkapital"
                                                    type="text"
                                                    placeholder="XXXX"
                                                />
                                                <DocField
                                                    id="ort"
                                                    label="Nachweis Guthaben 3. Säule"
                                                    type="text"
                                                    placeholder="Luzern"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {formState.eigentuemerSchaft === "zu zweit" &&
                            <div
                                className=""
                                id="adresse-1"
                            >

                                <div className="relative z-10 mt-16 mb-8 md:mb-2 flex gap-4">

                                    <div className={"mt-6 text-3xl leading-8 font-extrabold tracking-tight text-theme_blue pb-2 border-b-2 border-theme_blue border-x-none border-t-none flex flex-row w-full items-center justify-start"}>
                                        <h3 className={"max-w-prose lg:max-w-none flex"}>
                                            Miteigentümerschaft
                                        </h3>
                                    </div>


                                </div>

                                <AdressFormular
                                    handleInputChange={() => { }}
                                    formState={{}}
                                    options={[fieldsLeft, fieldsRight]}

                                />

                                <div className="mb-8 bg-white overflow-hidden text-left">
                                    <div className="max-w-max lg:max-w-7xl mx-auto">
                                        <H3>
                                            Dokumente
                                        </H3>
                                    </div>
                                    <div className="relative">

                                        <div className="relative md:bg-white pb-8">
                                            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                                    <DocField
                                                        id="name"
                                                        label="Gültige Ausweiskopie"
                                                        type="text"
                                                        placeholder="Mustermann"
                                                        autoFocus={true}
                                                    />
                                                    <DocField
                                                        id="adresse"
                                                        label="Lohnausweise"
                                                        type="text"
                                                        placeholder="Dorfstrasse 42"
                                                    />
                                                    <DocField
                                                        id="telefon"
                                                        label="Kopie der letzten Steuererklärung"
                                                        type="tel"
                                                        placeholder="XXX XXX XX XX"
                                                    />
                                                </div>
                                                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                                    <DocField
                                                        id="vorname"
                                                        label="Betreibungsregisterauszug"
                                                        type="text"
                                                        placeholder="Hans"
                                                    />
                                                    <DocField
                                                        id="plz"
                                                        label="Belege zu verfügbarem Eigenkapital"
                                                        type="text"
                                                        placeholder="XXXX"
                                                    />
                                                    <DocField
                                                        id="ort"
                                                        label="Nachweis Guthaben 3. Säule"
                                                        type="text"
                                                        placeholder="Luzern"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="mt-24 flex justify-center">
                            <a
                                href="/registrieren-3"
                                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-theme_green hover:bg-theme_blue transition-colors font-bold"
                            >
                                zum nächsten Schritt
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}